import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Blogs from "../components/Blogs"
import SEO from "../components/SEO"

const Blog = ({
  data: {
    allStrapiBlogs: { nodes: blogs },
  },
}) => {
  return (
    <Layout className="bg-grey">
      <SEO title="Blog" />
      <section className="blog-page">
        <Blogs title="Blog" blogs={blogs} />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {

    allStrapiBlogs(sort: { fields: date, order: DESC }) {
      nodes {
        slug
        content
        desc
        date(formatString: "DD/MM/YYYY")
        id
        title
        category {
          id
          title
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default Blog